@forward '../scss/globals';

.navbar{
    z-index: 6;
    position: fixed;
     top: 0;
     left: 0;
     min-height: 6rem;
     height: fit-content;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    text-decoration: none;
    list-style-type: none;
    padding-top: 1rem;
    padding-bottom: 1rem;
    color:black;
    background-color: white;
    box-shadow: black;
    font-size: 1.2rem;
}
.active
{
  color: var(--color-yellow);
}
.navbar .section
{   margin-top: 2rem;
    text-decoration: none;
    color: inherit;
    text-transform: uppercase;
    font-weight: 700;
    transition: border 300ms ease-in-out;
    border-bottom: 3px solid transparent;
    height: 2rem;
}
.navbar a
{
    text-decoration: none;
}
.navbar .section:hover
{
border-color: var(--color-yellow);
color: var(--color-yellow);
}
.logo
{   margin-top: 2rem;
    width: 13rem;
    transform: translateY(-1rem);
}

.mail
{   margin-top: 2rem;
    font-size: var(--font-xsmall);
    margin-bottom: 2rem;
    transform: translateY(1rem);
    color: gray;
    text-decoration: none;
}
.mail:hover
{
    color: var(--color-yellow);
}

.logo-container
{
    display: flex;
    flex: content;
    transition: all 300ms ease-in-out;
}
.logo-container:hover
{
 transform: translateY(-0.2rem);
}
.hamburger{
  margin: auto 0;
  display: none;
  font-size: 1.3em;
  color: var(--color-dark-cyan);
  cursor: pointer;
}
.hamburger div {
 width: 37px;
  height: 4px;
  background-color: white;
  margin: 6px 0;
  transition: 0.4s;
}


@media screen and (max-width: 600px) {
  
  .Logo-Area{width:100vw;}
    .section
    {
      font-size: 10px;
      padding-left: 1rem;
      padding-right: 1rem;
    }
    .navbar{
            display: flex;
            flex-direction: row;
            flex: auto;
            flex-wrap: wrap;
            justify-content: space-between;
          }
        .logo{
            width: 9rem;
            padding-left: 1rem;
        }
        .mail{
            display: flex!important;
            font-size: 9px;
            margin-top: 0;
            margin-bottom: 0;
            transform: translateY(-0.5rem);
            padding-left: 1rem;
    }
  .navbar.responsive {position: relative;}
  .navbar.responsive .icon {
    position: absolute;
    right: 0;
    top: 0;
  }
  .navbar.responsive a {
    float: none;
    display: block;
    text-align: left;
    
  }
  .navbar .logo-container
  {
    margin-left: 0;
    align-content: flex-start;
    flex-direction: column;
    
  }

}