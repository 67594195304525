.ContactUs
{
    display: flex;

}
.container
{
    width: 50%;
    justify-content: center;
}
.d-flex{
    width: 80%;
}
.ContactInfo
{
    width: 50%;
    padding-top: 6rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.contact-Title
{
    padding-bottom: 1.4rem;
}
.contactIcon
{
    padding-right: 1rem;
    color: var(--color-yellow);
}
.social
{
    padding: 1rem 0;

}