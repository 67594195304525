@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;700&display=swap');
:root{
    --font-xsmall: 0.7rem;
    --font-small: 1.2rem;
    --font-medium: 1.8rem;
    --font-large: 2rem;
    --font-x-large:3rem;
}
body{
    font-family: 'Ubuntu', sans-serif;

}
h1,h2,h3
{font-weight: 700;
line-height: 1.1;
margin-top: 0;}
button
{
    font-family: 'Ubuntu', sans-serif;
    font-weight: 700;
}
.p {
    display: block;
    margin-block-start: 0em;
    margin-block-end: 0em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
}