.Customers{
    min-height: 50vh;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.swiper-button-next
{
    color: var(--color-yellow) !important;
    right: 0 !important;
}
.swiper-button-prev
{
    color: var(--color-yellow) !important;
    left: 0 !important;
}
.customer{
    width: 150px;
    height: 150px;
}
.Title
{
    width: 100%;

    color: var(--color-yellow);
    text-align: center;
}
.SliderContainer
{
    width: 85%;
}
.logoContainer
{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}