.container
{   
    margin-top: 8rem;
    display: flex;
    flex-wrap: wrap;
    padding-top: 1rem;
    padding-bottom: 1rem;
    

}
 .icon-send{
        color: white;
        align-self: center;
    }

.send_icon
{
    width: 1rem;
    height: auto;
}
.text-area
{
    width: 30%;
}
.hero-text
{
    margin-top: 10rem;
    font-size: 20px;
}
.Banner
{
    width: 70%;
    height: 70%;
    padding-right: 2rem;
}
.break
{
    flex-basis: 100%;
    height: 0;
}
.button
{
    margin-top: 4rem;
    padding: 0.3rem;
    padding-left: 1rem;
    padding-right: 1rem;
    display: flex;
    width: fit-content;
    height: fit-content;
    background-color: var(--color-beige);
    border: 0;
    border-radius: 1rem;
    transition: all 200ms ease-in-out;
}
.send_logo
{   
    width: 2rem;
    fill: white;
}
.button .text
{
    padding-bottom: 0.2rem;
    padding: 0.3rem;
    color: white;
    font-size: large;
    align-self: center;
    padding-right: 0.4rem;
    padding-left: 0.4rem;
}
.button:hover
{
    background-color: var(--color-dark-beige);
    
}
.containerHome{
    padding-left: 11rem;
}

@media screen and (max-width: 600px) {
    .container
    {   padding-top: 10vh;
        padding-left: 0;
        width: 100%;
    }
    .text-area{
        width: 100%;
        margin-right: 0;
    }
    .hero-text{
        margin-top: 0.5rem;
        width: 100%;
    
    }
    .button{
       padding: 0.2rem;
       margin-bottom: 1rem;
    }
    .button .text{
        font-size: 0.8rem;
        padding: 0.2rem;
        padding-left: 0.3rem;
    }
   
    .Banner{width: 100%;}
}
