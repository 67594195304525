html
{
    box-sizing: border-box;
    font-size: 100%;
    overflow-x: hidden;

}

*,*::before,*::after{
    box-sizing: inherit;

}
body
{   margin: 0;
    padding: 0;
    font-family: Arial, Helvetica, sans-serif;
    background-color: rgb(255, 255, 255);
    color: black;
    overflow-x: hidden;
    padding-left: 0.4rem;
    padding-right: 0.4rem;
    
}
.container{
    width: 100%;
    overflow: hidden;
}
