
.container-our-team{
    background: #f1f1f1;
    display: flex;
    height: 40rem;
    padding-top: 5rem;
    padding: 2rem 2rem 2rem;
    width: 100%;
}
    .row{
    display: flex;
    flex-wrap: warp;
    justify-content: space-evenly;
    margin-top: 3rem;
    flex-direction: row;
    width: 90%;
}
 
.our-team{
    padding: 20px 15px 30px;
    margin:1rem;
    background: #fff;
    border-radius: 15px;
    text-align: center;
}
.our-team .pic{
    display: inline-block;
    width: 15rem;
    height: 15rem;
    background: #fff;
    padding: 10px;
    margin-bottom: 25px;
    transition: all 0.5s ease 0s;
}
.our-team:hover .pic{
    background: #17bebb;
    border-radius: 50%;
}
.pic img{
    width: 100%;
    height: auto;
    border-radius: 50%;
}
.our-team .title{
    display: block;
    font-size: 20px;
    font-weight: 600;
    color: #2e282a;
    text-transform: uppercase;
    margin: 0 0 7px 0;
}
.our-team .post{
    display: block;
    font-size: 15px;
    color: #17bebb;
    text-transform: capitalize;
    margin-bottom: 15px;
}
.our-team .social{
    padding: 0;
    margin: 0;
    list-style: none;
}
.our-team .social li{
    display: inline-block;
    margin-right: 5px;
}
.our-team .social li a{
    display: flex;
    width: 30px;
    height: 30px;
    line-height: 30px;
    border-radius: 50%;
    font-size: 15px;
    color: #17bebb;
    border: 1px solid #17bebb;
    transition: all 0.5s ease 0s;
    align-content: stretch;
    justify-content: center;
    align-items: center;
}
.our-team:hover .social li a{
    background: #17bebb;
    color: #fff;
}
@media only screen and (max-width: 990px){
    .row{
    display: flex;
    flex-wrap: warp;
    justify-content: space-evenly;
    margin-top: 3rem;
    flex-direction: column;
}
.our-team .pic
{
    width: 10rem;
    height: 10rem;
}
    .our-team{ margin-bottom: 30px; }
}


.socials
{
    height: 1.4rem;
    width: 1.4rem;
}
.container-our-team>.Title{
    padding-bottom: 0vh;}
.container-our-team{
    height: fit-content;
        justify-content: center;
        flex-direction: column;
            align-items: center;
}